.empty-list-container {
    display: grid;
    grid-template-rows: auto auto;
    align-content: center;
    justify-content: center;
    height: 100%;

    img {
        margin: 0 auto;
    }

    p {
        max-width: 75%;
        margin: 24px auto;

        /* ColdGray/5 */
        color: #a4a9b0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.0025em;
        line-height: 24px;
        text-align: center;
    }
}
