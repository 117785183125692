$aside-width: 344px;
$header-height: 56px;

.container {
    height: 100%;
}

.header {
    display: flex;
    z-index: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 1px 0 0 #dfdfdf;
}

.header-left-section {
    display: flex;
    gap: 16px;
    align-items: center;
}

.content {
    display: grid;
    right: $aside-width;
    left: $aside-width;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    align-content: flex-start;
    padding: 40px;
    overflow: auto;
    gap: 40px;

    > div {
        border-radius: 8px;
    }

    > div:nth-child(1) {
        grid-column: 1 / 4;
        grid-row: 1;
    }

    > div:nth-child(2) {
        grid-column: 1 / 3;
        grid-row: 2;
        background: white;
    }

    > div:nth-child(3) {
        grid-row: 2;
        width: 372px;
        background: white;
    }
}

.left-panel,
.right-panel,
.content {
    height: 100%;
}

.left-panel,
.right-panel {
    width: $aside-width;
    background: white;
}

.logo {
    width: 128px;
    height: 40px;
    cursor: pointer;
}
