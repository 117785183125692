.header {
    display: flex;
    z-index: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px !important;
    padding: 0 40px !important;
    box-shadow: 0 1px 0 0 #dfdfdf;
    line-height: unset !important;
}

.sections {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    div {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.right {
    justify-content: end;
}

.left {
    justify-content: start;
}

.logo {
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 0 4px;
    cursor: pointer;

    svg {
        width: 28px;
        height: 36px;
    }
}
