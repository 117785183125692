.menu {
    position: absolute !important;
    top: 8px;
    right: 12px;
    transition: 200ms;
    opacity: 0;
}

.card {
    overflow: hidden;
    transition: 200ms;

    :global(.ant-card-actions) {
        position: absolute;
        top: -100%;
        width: 100%;
        transition: 300ms;
        box-shadow: 0 2px 12px rgb(0 0 0 / 20%);
    }

    &:hover {
        :global(.ant-card-actions) {
            top: 0;
        }

        .menu {
            opacity: 1;
        }
    }
}
