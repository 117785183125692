.empty-results {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 167px;
    background: 'white';
}

.pagination {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 16px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    gap: 24px;
    margin-top: 12px;
    margin-bottom: 80px;
}
