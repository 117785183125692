.quick-start {
    padding: 32px;
    background-color: #2a2d31;
    color: white;
}

.title,
.sub-title,
.description {
    color: white !important;
    font-weight: normal !important;
}

.title {
    margin-top: 2px !important;
    margin-bottom: 8px !important;
}

.sub-title {
    font-size: 14px !important;
}

.description {
    margin-bottom: 32px;
    font-size: 16px;
}

.container {
    display: grid;
    grid-template-rows: auto 1fr;
}

.market-overviews {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    border-radius: 8px;
    background-color: #3a3a44;
}

.market-select {
    display: block;
    width: 100%;
}
