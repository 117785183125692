.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.color-picker-swatch {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 2px;
    background-color: rgb(0, 0, 255);
    cursor: pointer;
}

.layer-color-icon {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    cursor: pointer;
}

.radio-style {
    display: flex;
    align-items: center;
    margin: 0;

    span {
        top: 0;
    }

    span:nth-of-type(2) {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

;

.color-picker-input {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    min-width: 100px;
    height: 32px;
    margin-right: 8px;
    border-radius: 4px;
    cursor: pointer;

    div {
        position: absolute;
        height: 20px;
        width: 20px;
        margin: 0px 8px;
        border-radius: 4px;
    }

    .input[type=text] {
        position: initial;
        width: 112px;
        padding-left: 36px;
        background-color: #f3f3f3;
    }
}