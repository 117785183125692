.header {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 24px;

    h3 {
        align-self: center;
        margin: 0;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.0025em;
        line-height: 32px;
        vertical-align: bottom;
    }

    a {
        margin: 0;
        margin-left: auto;
        color: blue;
        text-decoration: none;
    }
}
