.signup-page {
    &__header {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        height: 100%;
        background-color: white;
    }

    &__blackbird-logo {
        width: 128px;
        height: 40px;
    }

    &__jll-logo {
        width: 70px;
        height: 32px;
    }

    &__content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 32px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }

    &__form-container {
        width: 512px;
        max-width: 100%;
    }

    &__form-links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
    }

    &__form-links-right {
        text-align: right;
    }
}
