html,
body,
#root {
    height: 100%;
    overflow: hidden;
}

button {
    cursor: pointer;
}

.ant-btn-icon [class*='material-symbols-'] {
    font-size: 20px;
}

.ant-btn {
    .ant-btn-icon {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
    }
}

.ant-tree-treenode,
.ant-tree-node-content-wrapper {
    width: 100%;
}

.ant-tree .ant-tree-treenode {
    height: 32px;
}

.ant-modal-header {
    h2 {
        max-width: 95%;
        margin: 0;
        margin-top: 0;
        overflow: hidden;
        color: #19191d;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 32px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ant-modal-title > div {
        margin: 0;
        color: #313541;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        letter-spacing: -0.005em;
        line-height: 32px;
    }
}

input + button.ant-btn {
    margin-left: 0;
}

[class^='ant-'] h5 {
    text-transform: unset;
}

iframe#react-refresh-overlay {
    display: none !important;
}

.ant-menu-item-selected {
    background-color: #f9f9f9;

    &:hover {
        background-color: #f9f9f9;
    }
}

.ant-modal-wrap {
    pointer-events: none;
}

.trep-bi-window {
    width: 400px;
    height: 100%;
}

.trep-bi-window__expand-icon {
    display: flex;
    align-items: center;
    width: 32px;
    filter: invert(0.5);

    i {
        transform: scaleX(-1);
        transform-origin: 35% center;
    }
}

.trep-bi-window__expand-icon--expanded {
    i {
        transform: scaleX(1);
    }
}

.trep-bi-window--expanded {
    top: 104px;
    left: 50%;
    width: 50vw;
}

.ant-form .ant-form-item-label > label.ant-form-item-required {
    padding-left: 6px;
}

.ant-form
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    top: 2px;
    margin-left: -6px;
    color: rgb(12 123 161 / 100%);
    font-size: 0.8em;
    vertical-align: top;
}

.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item {
    display: flex;
    align-items: center;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:focus,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-prev:hover {
    display: flex !important;
    z-index: 10;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    opacity: 0.8;
    background-color: #292a31;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.ant-carousel .slick-prev::after,
.ant-carousel .slick-prev::before,
.ant-carousel .slick-next::after,
.ant-carousel .slick-next::before {
    border: none;
}

.ant-carousel .slick-next {
    right: 8px;
}

.ant-carousel .slick-prev {
    left: 8px;
}

.esri-ui-bottom-left {
    flex-flow: column !important;
    gap: 10px;

    div {
        margin-right: 0 !important;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in-down {
    from {
        transform: translateY(-5px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-up {
    from {
        transform: translateY(5px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-left {
    from {
        transform: translateX(-5px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    from {
        transform: translateX(5px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.fade-in {
    animation-name: fade-in;
    animation-duration: 300ms;
    animation-fill-mode: both;
}

.fade-in-down {
    animation-name: fade-in-down;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.fade-in-up {
    animation-name: fade-in-up;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.fade-in-left {
    animation-name: fade-in-left;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.fade-in-right {
    animation-name: fade-in-right;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.dark-theme {
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar,
    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgb(255 255 255 / 20%);
    }

    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.calcite-mode-dark .esri-widget {
    transition: background-color 200ms;
    background-color: rgb(1 21 28);
}
