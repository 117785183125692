.list-item {
    display: grid;
    grid-template-columns: auto 1fr;
    margin: 24px 0;
    transition: 200ms;
    white-space: nowrap;
    cursor: pointer;
    gap: 16px;

    p {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-of-type {
            margin-top: 4px;
            color: #60666e;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        &:last-of-type {
            color: #2a2d31;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    h3 {
        margin: -2px 0;
        color: #2a2d31;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    &:hover {
        filter: brightness(0.9);
    }
}

.list-item-section {
    &:first-child {
        width: 64px;
        height: 64px;
        overflow: hidden;
        border-radius: 4px;
    }

    &:last-child {
        overflow: hidden;
    }
}
