.container {
    display: grid;
    grid-template-rows: auto 1fr;
    width: inherit;
    overflow: hidden;
}

.header {
    padding: 24px;
}

.list {
    box-sizing: fit-content;
    height: 100%;
    margin: 0;
    padding: 0 32px;
    padding-bottom: 24px;
    overflow-y: auto;
    gap: 24px;
}
