.grid-item {
    transition: 200ms;
    cursor: pointer;

    &:hover {
        filter: brightness(0.9);
    }

    .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 136px;
        overflow: hidden;
        border-radius: 4px;
        background: #ddd;
    }
}

.selected {
    .image-container {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border: 2px solid rgb(150 0 0 / 40%);
            border-radius: 4px;
        }
    }
}

.description,
.sub-title,
.title {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
