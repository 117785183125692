.container {
    display: grid;
    grid-template-rows: auto 1fr;
    width: inherit;
    overflow: hidden;
    border-top: 1px solid rgb(0 0 0 / 10%);
}

.header {
    padding: 24px;
}

.list {
    display: flex;
    box-sizing: fit-content;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0 32px;
    padding-bottom: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 16px;
}

.list-item {
    display: flex;
    flex-wrap: nowrap;
    transition: 200ms;
    gap: 12px;

    &:hover {
        opacity: 0.75;
    }
}

.empty-results {
    display: flex;
    position: relative;
    top: -24px;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: 'white';
}
