.left-panel {
    display: grid;
    grid-template-rows: auto minmax(auto, 1fr);
    height: 100%;
    overflow: auto;
    background-color: white;
    color: #60666e;
}

.content-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    span,
    a {
        /* palette/ColdGray/7 */
        color: #60666e;
        font-size: 16px;
        line-height: 24px;
    }

    > a {
        padding: 24px 40px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            background: #f6f7f7;
        }
    }

    .tag {
        display: flex;
        position: static;
        top: 0;
        left: 205px;
        box-sizing: border-box;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 59px;
        height: 24px;
        padding: 6px 12px;
        border-radius: 64px;

        /* ColdGray/3 */
        background: #dddfe1;

        /* ColdGray/10 */
        color: #2a2d31;
        font-size: 14px;
        line-height: 20px;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 40px;
    }
}

.selected {
    background: #f6f7f7;
    color: #40a9ff !important;
}
